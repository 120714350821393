.main {
  margin-left: 110px;
}

@media screen and (max-width: 1024px) {
  .main {
    margin-left: 0;
  }
}

.centered-content {
  text-align: center;
  margin-top: 20px;
}
